import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default ({ location }) => {
  return (
    <Layout location={location} pageTitle="404">
      <SEO title="404" />
      <div className="container mx-auto p-6 mt-10">
        <div className="flex flex-col items-center">
          <h1 className="text-6xl font-bold">Error 404 - Not Found</h1>
          <p className="text-xl mb-6">
            We can't find the page you're looking for, try going back or
            visiting a different link.
          </p>
          <Link
            to="/"
            className="flex items-center justify-center px-5 py-3 border border-transparent text-base uppercase leading-6 font-medium rounded-md text-white bg-brand hover:bg-brand-lighter focus:outline-none focus:bg-brand-lighter transition duration-150 ease-in-out"
          >
            Back to Stimpack
          </Link>
        </div>
      </div>
    </Layout>
  )
}
